import requests from "./httpService";

const ApplicationServices = {
  getInformation() {
    return requests.get(`/information/`);
  },
  getInformationById(id) {
    return requests.get(`/information/${id}`);
  },
  getCities() {
    return requests.get(`/cities/all`);
  },
  updateCitiesStatue(id) {
    return requests.get(`/cities/status/${id}`);
  },
  addCitie(body) {
    return requests.post(`/cities/add`, body);
  },
  updateCity(id, body) {
    return requests.put(`/cities/${id}`, body);
  },

  getLoanPurpose() {
    return requests.get(`/purpose/all`);
  },
  updatePurposeStatue(id) {
    return requests.get(`/purpose/status/${id}`);
  },
  addPurpose(body) {
    return requests.post(`/purpose/add`, body);
  },
  updatePurpose(id, body) {
    return requests.put(`/purpose/${id}`, body);
  },
};

export default ApplicationServices;
