import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import Footer from "../../Layouts/Footer";
import ApplicationServices from "../../services/ApplicationServices";
import { notifyError, notifySuccess } from "../../utils/toast";
import Skeleton from "react-loading-skeleton";

const LoanPurpose = () => {
  const [allPurpose, setAllPurpose] = useState([]);
  // Add Cities States
  const [enName, setEnName] = useState("");
  const [urName, setUrName] = useState("");
  const [isActive, setIsActive] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // editing states
  const [enEditName, setEnEditName] = useState("");
  const [urEditName, setUrEditName] = useState("");

  const [editId, setEditId] = useState("");

  const getData = () => {
    setIsLoading(true);
    ApplicationServices.getLoanPurpose()
      .then((res) => {
        setAllPurpose(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
    setIsActive(true);
  }, []);

  const updateStatus = (id) => {
    ApplicationServices.updatePurposeStatue(id)
      .then((res) => {
        notifySuccess("Purpose Status Updated!");
      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
      });
  };

  const addCities = (e) => {
    e.preventDefault();
    const link = document.getElementById("PurposeForm");
    const body = {
      en_name: enName,
      ur_name: urName,
      is_active: isActive,
    };
    ApplicationServices.addPurpose(body)
      .then((res) => {
        link.click();
        getData();
        notifySuccess("Purpose Added Successfully!");
      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
      });
  };

  const handelEditingChange = (e, item) => {
    setEditId(item._id);
    setIsEditing(true);
    setEnEditName(item.en_name);
    setUrEditName(item.ur_name);
  };

  const handelEditing = (e) => {
    e.preventDefault();
    setIsEditing(false);
    const body = {
      en_name: enEditName,
      ur_name: urEditName,
    };

    ApplicationServices.updatePurpose(editId, body)
      .then((res) => {
        notifySuccess("Updated!");
        getData();
      })
      .catch((err) => {
        notifyError(err ? err.response.data.message : err.message);
      });
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Purpose</h4>
                  <div className="page-title-right">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                    >
                      <i
                        className="fas fa-plus"
                        style={{ marginRight: "5px" }}
                      />
                      Add Purpose
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>Purpose Name (English)</th>
                          <th>Purpose Name (Urdu)</th>
                          <th>Is Active</th> <th>Actions</th>
                        </tr>
                      </thead>

                      {isLoading === true ? (
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {allPurpose &&
                            allPurpose.map((el, index) => (
                              <tr
                                key={el._id}
                                className={index % 2 === 0 ? "" : "row-even"}
                              >
                                <td>
                                  {isEditing === true && editId === el._id ? (
                                    <>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={enEditName}
                                        onChange={(e) =>
                                          setEnEditName(e.target.value)
                                        }
                                        id="example-text-input"
                                        style={{ width: "auto" }}
                                      />
                                    </>
                                  ) : (
                                    <>{el.en_name}</>
                                  )}
                                </td>
                                <td>
                                  {isEditing === true && editId === el._id ? (
                                    <>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={urEditName}
                                        onChange={(e) =>
                                          setUrEditName(e.target.value)
                                        }
                                        id="example-text-input"
                                        style={{ width: "auto" }}
                                      />
                                    </>
                                  ) : (
                                    <>{el.ur_name}</>
                                  )}
                                </td>
                                <td>
                                  <Switch
                                    defaultChecked={el.is_active === true}
                                    onChange={() => updateStatus(el._id)}
                                    // onChange={onChange}
                                    style={{ backgroundColor: "#0081c3" }}
                                  />
                                </td>
                                <td>
                                  {isEditing === true && editId === el._id ? (
                                    <>
                                      <span
                                        className="btn btn-outline-secondary btn-sm edit"
                                        style={{ marginRight: "5px" }}
                                        title="Edit"
                                        onClick={(e) => handelEditing(e)}
                                      >
                                        <i
                                          className="fas fa-save"
                                          title="Save"
                                        />
                                      </span>
                                      <span
                                        className="btn btn-outline-danger btn-sm edit"
                                        title="Edit"
                                        onClick={() => setIsEditing(false)}
                                      >
                                        <i
                                          className="fas fa-minus"
                                          title="Save"
                                        />
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      className="btn btn-outline-primary btn-sm edit"
                                      title="Edit"
                                      onClick={(e) =>
                                        handelEditingChange(e, el)
                                      }
                                    >
                                      <i className="fas fa-pencil-alt" />
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="myModal"
              className="modal fade"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="myModalLabel">
                      Add Purpose
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      id="PurposeForm"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <form
                    onSubmit={(e) => addCities(e)}
                    className="needs-validation"
                    noValidate
                  >
                    <>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom01"
                              >
                                Purpose Name (in Englist)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Purpose Name (in Englist)"
                                value={enName}
                                onChange={(e) => setEnName(e.target.value)}
                                required
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom02"
                              >
                                Purpose Name (in Urdu)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Purpose Name (in Urdu)"
                                value={urName}
                                onChange={(e) => setUrName(e.target.value)}
                                required
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light waves-effect"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Save changes
                        </button>
                      </div>
                    </>
                  </form>
                </div>
                {/* /.modal-content */}
              </div>
              {/* /.modal-dialog */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoanPurpose;
