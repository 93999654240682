import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { notifySuccess } from "../utils/toast";

const Header = (props) => {
  const { handleChange } = props;
  const [adminInfo, setAdminInfo] = useState(null);
  const navigate = useNavigate();

  console.log(adminInfo);

  useEffect(() => {
    const AdminData = JSON.parse(secureLocalStorage.getItem("adminInfo"));
    setAdminInfo(AdminData);
  }, []);

  const Logout = (e) => {
    e.preventDefault();
    secureLocalStorage.removeItem("adminInfo");
    navigate("/");
    notifySuccess("User Loged Out");
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* LOGO */}
            <div className="navbar-brand-box">
              <Link to="index.html" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="/assets/images/logo-sm.png" alt="" height={22} />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo-dark.png" alt="" height={20} />
                </span>
              </Link>
              <Link to="index.html" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/assets/images/logo-sm.png" alt="" height={22} />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo-light.png" alt="" height={20} />
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              onClick={handleChange}
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="uil-search" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={
                    process.env.REACT_APP_API_BASE_IMAGE_URL +
                    `/` +
                    adminInfo?.image
                  }
                  alt={adminInfo?.image}
                />
                <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
                  {adminInfo?.name}
                </span>
                <i className="uil-angle-down d-none d-xl-inline-block font-size-15" />
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to="/change_password">
                  <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted" />
                  <span className="align-middle">Change Password</span>
                </Link>
                <Link className="dropdown-item" to="/lock_screen">
                  <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted" />
                  <span className="align-middle">Lock screen</span>
                </Link>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={(e) => Logout(e)}
                >
                  <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted" />
                  <span className="align-middle">Sign out</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
