import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ApplicationServices from "../../services/ApplicationServices";
import ApplicationDetail from "./ApplicationDetail";
import { DatePicker } from "antd";
import Footer from "../../Layouts/Footer";
import Skeleton from "react-loading-skeleton";
import { CSVLink } from "react-csv";

const { RangePicker } = DatePicker;

const Applications = () => {
  const [getAllInformation, setGetAllInformation] = useState([]);
  const [getAllInformationTemp, setGetAllInformationTemp] = useState([]);
  const [applicationData, setApplicationData] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  console.log("getAllInformation", getAllInformation[0]);

  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchBy, setSearchBy] = useState("");

  const [startDateClick, setStartDateClick] = useState("");
  const [endDateClick, setEndDateClick] = useState("");

  // CSV

  const headers = [
    { label: "Application ID", key: "applicationId" },
    { label: "Application Date", key: "createdAt" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "CNIC #", key: "cnic_number" },
    { label: "CNIC Expiry", key: "expiry_date" },
    { label: "Contact Number", key: "mobile_number" },
    { label: "Loan Ammount", key: "loan_ammount" },
    { label: "Occupation", key: "current_occupation" },
    { label: "Permanent City", key: "permanent_city" },
    { label: "Permanent Address", key: "permanent_address" },
    { label: "DOB", key: "date_of_birth" },
  ];

  const getData = () => {
    setIsLoading(true);
    ApplicationServices.getInformation()
      .then((res) => {
        setGetAllInformation(res);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const getApplicationData = (e, id) => {
    e.preventDefault();
    ApplicationServices.getInformationById(id).then((res) => {
      setApplicationData(res);
    });
  };

  const onButtonClick = () => {
    setStartDate(startDateClick);
    setEndDate(endDateClick);
  };

  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };

  const filterDataInDateRange = (data) => {
    if (startDate === "" && endDate === "") {
      return data;
    } else {
      const newData = data.filter(
        (item) =>
          moment(item.createdAt, "YYYY/MM/DD").format("YYYY/MM/DD") >=
            moment(startDate, "YYYY/MM/DD").format("YYYY/MM/DD") &&
          moment(item.createdAt, "YYYY/MM/DD").format("YYYY/MM/DD") <=
            moment(endDate, "YYYY/MM/DD").format("YYYY/MM/DD")
      );
      return newData;
    }
  };

  const handelSearch = (data) => {
    if (searchValue === "") {
      return data;
    } else if (searchValue !== "") {
      if (searchBy === "first_name") {
        return data.filter((el) =>
          el.first_name?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      if (searchBy === "last_name") {
        return data.filter((el) =>
          el.last_name?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      if (searchBy === "cnic_number") {
        return data.filter((el) =>
          el.cnic_number?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      if (searchBy === "permanent_city") {
        return data.filter((el) =>
          el.permanent_city?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      if (searchBy === "mobile_number") {
        return data.filter((el) =>
          el.mobile_number?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
    }
  };

  let tempArra = [];

  const allFilter = (data) => {
    const newData = handelSearch(filterDataInDateRange(data));
    return newData;
  };

  return (
    <>
      <Helmet>
        <title>Applications - Agahi Pakistan</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Applications</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Applications</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <div className="float-end">
                  <div className=" mb-3">
                    <RangePicker
                      allowClear="true"
                      onCalendarChange={handleCalendarChange}
                    />
                    &nbsp;
                    <button
                      type="button"
                      onClick={() => onButtonClick(true)}
                      className="btn btn-primary btn-sm waves-effect waves-light"
                    >
                      <i
                        className="mdi mdi-magnify"
                        style={{ marginRight: "5px" }}
                      />
                      Search
                    </button>
                    &nbsp;
                    <CSVLink
                      data={allFilter(getAllInformation && getAllInformation)}
                      headers={headers}
                    >
                      <button
                        type="button"
                        className="btn btn-primary btn-sm waves-effect waves-light"
                      >
                        <i
                          className="mdi mdi-cloud-download-outline"
                          style={{ marginRight: "5px" }}
                        />
                        Download
                      </button>
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex" style={{ justifyContent: "end" }}>
                    <div className="row w-30 mb-3">
                      <div
                        className="col-2"
                        style={{
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <label
                          style={{
                            fontWeight: "normal",
                            whiteSpace: "nowrap",
                            width: "150px",
                            alignItems: "center",
                          }}
                        >
                          Search:
                        </label>
                      </div>

                      <div className="col-5">
                        <select
                          className="form-select form-select-sm"
                          value={searchBy}
                          onChange={(e) => setSearchBy(e.target.value)}
                        >
                          <option value="">Search By</option>
                          <option value="first_name">First Name</option>
                          <option value="last_name">Last Name</option>
                          <option value="cnic_number">CNIC</option>
                          <option value="permanent_city">City</option>
                          <option value="mobile_number">Mobile#</option>
                        </select>
                      </div>
                      <div className="col-5">
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder=""
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/*  */}

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>Application Date</th>
                          <th>Full Name</th>
                          <th>CNIC #</th>
                          <th>CNIC Expiry</th>
                          <th>Purpose of loan</th>
                          <th>Permanent City</th>
                          <th>DOB</th>
                        </tr>
                      </thead>
                      {isLoading ? (
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <th>
                              <Skeleton />
                            </th>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <th>
                              <Skeleton />
                            </th>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <th>
                              <Skeleton />
                            </th>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <th>
                              <Skeleton />
                            </th>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <th>
                              <Skeleton />
                            </th>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <th>
                              <Skeleton />
                            </th>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {allFilter(
                            getAllInformation && getAllInformation
                          )?.map((el, index) => (
                            <tr
                              key={el._id}
                              className={index % 2 === 0 ? "" : "row-even"}
                            >
                              <td>
                                {moment(el.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <th>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    borderBottom: "1px",
                                    textDecoration: "underline",
                                    textDecorationStyle: "dotted",
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalFullscreen"
                                  onClick={(e) => getApplicationData(e, el._id)}
                                >
                                  {el.first_name} {el.last_name}
                                </span>
                              </th>
                              <td>{el.cnic_number}</td>
                              <td>{el.expiry_date}</td>
                              <td>{el.purpose_of_loan}</td>
                              <td>{el.permanent_city}</td>
                              <td>{el.date_of_birth}</td>
                            </tr>
                          ))}

                          <ApplicationDetail
                            applicationData={applicationData}
                          />
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Applications;
