import React from "react";
import { Route, Routes } from "react-router-dom";
import Applications from "./Pages/Applications/Applications";
import Login from "./Pages/Auth/Login";
import Cities from "./Pages/Cities/Cities";
import LoanPurpose from "./Pages/LoanPurpose/LoanPurpose";
import Dashboard from "./Pages/Home/Dashboard";
import PrivateComponent from "./Private/PrivateComponent";
import LockScreen from "./Pages/Auth/LockScreen";
import ChangePass from "./Pages/Auth/ChangePass";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/lock_screen" element={<LockScreen />} />
        <Route path="/change_password" element={<ChangePass />} />
        <Route element={<PrivateComponent />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/cities" element={<Cities />} />
          <Route path="/loan_purpose" element={<LoanPurpose />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
