import moment from "moment";
import React from "react";

const ApplicationDetail = (props) => {
  const { applicationData } = props;
  return (
    <>
      <div
        id="exampleModalFullscreen"
        className="modal fade"
        tabIndex={-1}
        aria-labelledby="#exampleModalFullscreenLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalFullscreenLabel">
                {applicationData?.applicationId} -{applicationData?.first_name}{" "}
                {applicationData?.last_name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row" style={{ padding: "15px 25px " }}>
                <div className="col-md-6" style={{ padding: "15px 25px " }}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={applicationData?.first_name}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-password-input"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-password-input"
                          value={applicationData?.last_name}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          Date of Birth
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={moment(applicationData?.date_of_birth).format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={applicationData?.mobile_number}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          CNIC Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={applicationData?.cnic_number}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-password-input"
                        >
                          Expiry Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-password-input"
                          value={moment(applicationData?.expiry_date).format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-password-input"
                        >
                          Current Address
                        </label>
                        <textarea
                          className="form-control"
                          rows={3}
                          value={applicationData?.current_address}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-password-input"
                        >
                          Permanent Address
                        </label>
                        <textarea
                          className="form-control"
                          rows={3}
                          value={applicationData?.permanent_address}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          Current City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={applicationData?.current_city}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-password-input"
                        >
                          Permanent City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-password-input"
                          value={applicationData?.permanent_city}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" style={{ padding: "15px 25px " }}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-password-input"
                        >
                          Amount of Loan
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-password-input"
                          value={applicationData?.loan_ammount}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          Current Occupation
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={applicationData?.current_occupation}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          Nature of Business
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={applicationData?.nature_of_business}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          Duration of Business
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={applicationData?.duration_of_business}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-password-input"
                        >
                          Complete Business Address
                        </label>
                        <textarea
                          className="form-control"
                          rows={3}
                          value={applicationData?.business_address}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-email-input"
                        >
                          Business City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-email-input"
                          value={applicationData?.business_city}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light waves-effect"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </>
  );
};

export default ApplicationDetail;
