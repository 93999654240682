import React, { useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ApplicationServices from "../../services/ApplicationServices";
import moment from "moment";
import { Helmet } from "react-helmet";
import DataFunction from "../../Functions/AllFunctions";
import Footer from "../../Layouts/Footer";

const Dashboard = () => {
  const [getAllInformation, setGetAllInformation] = useState([]);
  const [numberOfAppLastYear, setNumberOfAppLastYear] = useState("");

  const getData = () => {
    ApplicationServices.getInformation().then((res) => {
      setGetAllInformation(res);
    });
  };

  const currentDay = getAllInformation.filter(
    (el) =>
      moment(el.createdAt).format("DD/MM/YYYY") ===
      moment().format("DD/MM/YYYY")
  ).length;
  const currentMonth = getAllInformation.filter(
    (el) =>
      moment(el.createdAt).format("MM/YYYY") === moment().format("MM/YYYY")
  ).length;
  const currentYear = getAllInformation.filter(
    (el) => moment(el.createdAt).format("YYYY") === moment().format("YYYY")
  ).length;

  const percentageDate = useCallback(() => {
    let previous = getAllInformation.filter(
      (el) =>
        moment(el.createdAt).format("DD/MM/YYYY") ===
        moment().subtract(1).format("DD/MM/YYYY")
    ).length;
    let result = (currentDay / previous - 1) * 100;

    if (previous === 0) {
      return <p className="text-muted mt-3 mb-0">Not Enough Data</p>;
    }

    if (result < 1) {
      return (
        <p className="text-muted mt-3 mb-0">
          <span className="text-danger me-1">
            <i className="mdi mdi-arrow-down-bold me-1" />
            {result?.toFixed(1)}%
          </span>
          since Yesterday
        </p>
      );
    } else if (result >= 1) {
      return (
        <p className="text-muted mt-3 mb-0">
          <span className="text-success me-1">
            <i className="mdi mdi-arrow-up-bold me-1" />
            {result?.toFixed(1)}%
          </span>
          since Yesterday
        </p>
      );
    }
  }, [currentDay, getAllInformation]);

  const percentageMonth = () => {
    let previous = getAllInformation.filter(
      (el) =>
        moment(el.createdAt).format("MM/YYYY") ===
        moment().subtract(1, "month").format("MM/YYYY")
    ).length;
    let result = (currentMonth / previous - 1) * 100;

    if (previous === 0) {
      return <p className="text-muted mt-3 mb-0">Not Enough Data</p>;
    }
    if (result < 1) {
      return (
        <p className="text-muted mt-3 mb-0">
          <span className="text-danger me-1">
            <i className="mdi mdi-arrow-down-bold me-1" />
            {result?.toFixed(1)}%
          </span>
          since last Month
        </p>
      );
    } else if (result >= 1) {
      return (
        <p className="text-muted mt-3 mb-0">
          <span className="text-success me-1">
            <i className="mdi mdi-arrow-up-bold me-1" />
            {result?.toFixed(1)}%
          </span>
          since last Month
        </p>
      );
    }
  };
  const percentageYear = () => {
    let previous = getAllInformation.filter(
      (el) =>
        moment(el.createdAt).format("YYYY") ===
        moment().subtract(1, "year").format("YYYY")
    ).length;
    let result = (currentYear / previous - 1) * 100;

    // console.log("resylt in Year", result);

    if (previous === 0) {
      return <p className="text-muted mt-3 mb-0">Not Enough Data</p>;
    }

    if (result < 1) {
      return (
        <p className="text-muted mt-3 mb-0">
          <span className="text-danger me-1">
            <i className="mdi mdi-arrow-down-bold me-1" />
            {result?.toFixed(1)}%
          </span>
          since last Year
        </p>
      );
    } else if (result >= 1) {
      return (
        <p className="text-muted mt-3 mb-0">
          <span className="text-success me-1">
            <i className="mdi mdi-arrow-up-bold me-1" />
            {result?.toFixed(1)}%
          </span>
          since last Year
        </p>
      );
    }
  };

  useEffect(() => {
    getData();
    percentageDate();
  }, []);

  useEffect(() => {
    const numberOfApplicationsLastYear = () => {
      const oneYearAgo = moment().subtract(1, "year");
      const submittedForms = getAllInformation.filter((form) =>
        moment(form.createdAt).isAfter(oneYearAgo)
      );
      setNumberOfAppLastYear(submittedForms.length);
    };

    numberOfApplicationsLastYear();
  }, [getAllInformation]);

  return (
    <>
      <Helmet>
        <title>Agahe Pakistan - Dashboard</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="float-end mt-2"></div>
                    <div>
                      <h4 className="mb-1 mt-1">
                        <span data-plugin="counterup">
                          {getAllInformation && getAllInformation.length}
                        </span>
                      </h4>
                      <p className="text-muted mb-0">Total Applications</p>
                    </div>
                    <p className="mt-3 mb-0" style={{ color: "#fff" }}>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="float-end mt-2">
                      <div id="orders-chart" />
                    </div>
                    <div>
                      <h4 className="mb-1 mt-1">
                        <span data-plugin="counterup">{currentDay}</span>
                      </h4>
                      <p className="text-muted mb-0">Applications Today</p>
                    </div>
                    {percentageDate()}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="float-end mt-2">
                      <div id="customers-chart" />
                    </div>
                    <div>
                      <h4 className="mb-1 mt-1">
                        <span data-plugin="counterup">{currentMonth}</span>
                      </h4>
                      <p className="text-muted mb-0">Applications This Month</p>
                    </div>
                    {percentageMonth()}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3">
                <div className="card">
                  <div className="card-body">
                    <div className="float-end mt-2">
                      <div id="growth-chart" />
                    </div>
                    <div>
                      <h4 className="mb-1 mt-1">
                        <span data-plugin="counterup">{currentYear}</span>
                      </h4>
                      <p className="text-muted mb-0">Applications This Year</p>
                    </div>
                    {percentageYear()}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">
                      Applications (in Last 1 Year)
                    </h4>
                    <div className="mt-1">
                      <ul className="list-inline main-chart mb-0">
                        <li className="list-inline-item chart-border-left me-0 border-0">
                          <h3 className="text-primary">
                            <span data-plugin="counterup">
                              {numberOfAppLastYear}
                            </span>
                            <span className="text-muted d-inline-block font-size-15 ms-3">
                              Applications
                            </span>
                          </h3>
                        </li>
                        <li className="list-inline-item chart-border-left me-0">
                          <h3>
                            <span data-plugin="counterup">
                              {(numberOfAppLastYear / 12)?.toFixed(1)}
                            </span>
                            <span className="text-muted d-inline-block font-size-15 ms-3">
                              /per month
                            </span>
                          </h3>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-3">
                      <ReactApexChart
                        options={
                          DataFunction?.getChartLast1YearTest(getAllInformation)
                            ?.options
                        }
                        series={
                          DataFunction?.getChartLast1YearTest(getAllInformation)
                            ?.series
                        }
                        type="bar"
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Top Cities</h4>
                    <ReactApexChart
                      options={
                        DataFunction.getChartData(getAllInformation).options
                      }
                      series={
                        DataFunction.getChartData(getAllInformation).series
                      }
                      type="pie"
                      width={380}
                    />
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Purpose Of Loan</h4>
                    <ReactApexChart
                      options={
                        DataFunction.getChartDataLoan(getAllInformation).options
                      }
                      series={
                        DataFunction.getChartDataLoan(getAllInformation).series
                      }
                      type="pie"
                      width={380}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
